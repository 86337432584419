<!-- 
  页面 首页  
-->
<template>
  <div id="home">

    <div class="home-header">
      
        <div class="content" itemprop="acticleBody">

            <div class="vx-row">
                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          
                    <el-divider content-position="left" class="inline-block">实有房屋</el-divider>
                    <!-- 图表1-3 -->
                    <div class="vx-row">
                        <div class="vx-col w-full xl:w-1/3  mb-base">
                            <statistics-card-line icon="HomeIcon" :statistic="house.ownerHouseCount" statisticTitle="自住房" :chartData="analyticsData(house.ownerHouseCount)" type='area'></statistics-card-line>
                        </div>
                        <div class="vx-col w-full xl:w-1/3  mb-base">
                            <statistics-card-line icon="HomeIcon" :statistic="house.rentHouseCount" statisticTitle="出租房" :chartData="analyticsData(house.rentHouseCount)" color='success' type='area'></statistics-card-line>
                        </div>
                        <div class="vx-col w-full xl:w-1/3  mb-base">
                            <statistics-card-line icon="HomeIcon" :statistic="house.noPeopleHouseCount" statisticTitle="空置房" :chartData="analyticsData(house.noPeopleHouseCount)" color='orange' type='area'></statistics-card-line>
                        </div>
                    </div>
                </div>

                <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">

                    <el-divider content-position="left" class="inline-block">实有人口</el-divider>
                    <!-- 图表1-3 -->
                    <div class="vx-row">
                        <div class="vx-col w-full xl:w-1/3 mb-base">
                            <statistics-card-line icon="UsersIcon" :statistic="population.localCount" statisticTitle="户籍人员" :chartData="analyticsData(population.localCount)" type='area'></statistics-card-line>
                        </div>
                        <div class="vx-col w-full xl:w-1/3 mb-base">
                            <statistics-card-line icon="UsersIcon" :statistic="population.outCount" statisticTitle="外来人口" :chartData="analyticsData(population.outCount)" color='success' type='area'></statistics-card-line>
                        </div>
                    </div>
                </div>

            </div>

          <el-divider content-position="left" class="inline-block">实有设备</el-divider>
          <!-- 图表1-3 -->
          <div class="vx-row">
              <div class="vx-col w-full xl:w-1/6 mb-base">
                  <statistics-card-line icon="PrinterIcon" :statistic="device.accessCount" statisticTitle="门禁设备" :chartData="analyticsData(device.accessCount)" type='area'></statistics-card-line>
              </div>
              <div class="vx-col w-full xl:w-1/6 mb-base">
                  <statistics-card-line icon="PrinterIcon" :statistic="device.cameraCount" statisticTitle="监控设备" :chartData="analyticsData(device.cameraCount)" color='success' type='area'></statistics-card-line>
              </div>
              <div class="vx-col w-full xl:w-1/6 mb-base">
                  <statistics-card-line icon="PrinterIcon" :statistic="device.nbCount" statisticTitle="感知设备" :chartData="analyticsData(device.nbCount)" color='orange' type='area'></statistics-card-line>
              </div>
              <div class="vx-col w-full xl:w-1/6 mb-base">
                  <statistics-card-line icon="PrinterIcon" :statistic="device.otherCount" statisticTitle="其他设备" :chartData="analyticsData(device.otherCount)" color='purple' type='area'></statistics-card-line>
              </div>
          </div>


          <el-divider content-position="left" class="inline-block">信息采集</el-divider>
          <!-- 图表1-3 -->
          <div class="vx-row">
              <div class="vx-col w-full xl:w-1/6  mb-base">
                  <statistics-card-line icon="ClipboardIcon" :statistic="collection.allCollectionCount" statisticTitle="累计采集" :chartData="analyticsData(collection.allCollectionCount)" type='area'></statistics-card-line>
              </div>
              <!-- <div class="vx-col w-full xl:w-1/6  mb-base">
                  <statistics-card-line icon="ClipboardIcon" :statistic="collection.cardCount" statisticTitle="累计发卡" :chartData="analyticsData(collection.cardCount)" color='success' type='area'></statistics-card-line>
              </div>
              <div class="vx-col w-full xl:w-1/6  mb-base">
                  <statistics-card-line icon="ClipboardIcon" :statistic="collection.faceCount" statisticTitle="人脸采集" :chartData="analyticsData(collection.faceCount)" color='orange' type='area'></statistics-card-line>
              </div> -->
          </div>

        </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '../components/statistics-cards/StatisticsCardLine'

export default{
    data() {
        return {
            villageCode: "",
            population: {
                localCount: 0,
                outCount: 0
            },
            collection: {
                allCollectionCount: 0,
                cardCount: 0,
                faceCount: 0
            },
            device: {
                accessCount: 0,
                cameraCount: 0,
                nbCount: 0,
                otherCount: 0
            },
            house:{
                noPeopleHouseCount: 0,
                ownerHouseCount: 0,
                rentHouseCount: 0
            }
        }
    },
    components: {
        StatisticsCardLine,
        VueApexCharts
    },
    watch: {
        async "$store.state.auth.space.space_code"(val) {
            this.villageCode = this.$store.state.auth.space.space_code
            this.population = await this.getStatisticalCount("人口统计数据")
            this.collection = await this.getStatisticalCount("采集统计数据")
            this.device = await this.getStatisticalCount("设备统计数据")
            this.house = await this.getStatisticalCount("房屋统计数据")
        }
  },
    mounted() {
    },
    methods: {
        // 统计图标
        analyticsData(series_data) {
            return {
                series: [{
                    name: '数量',
                    data: [0,series_data]
                }],
                chartOptions: {
                    // colors: ['#77B6EA'],
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2.5
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            // gradientToColors: [ '#FDD835'],
                            shadeIntensity: 0.9,
                            opacityFrom: 0.7,
                            opacityTo: 0.5,
                            stops: [0, 80, 100]
                        }
                    },
                    xaxis: {
                        type: 'numeric',
                        axisTicks: {
                            show:false,
                        },
                        lines: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                        labels: { show: false },
                    },
                    yaxis: [{
                        show: false,
                        y: 0,
                        offsetX: 0,
                        offsetY: 0,
                        padding: { left: 0, right: 0 }
                    }],
                    tooltip: {
                        x: { show: false }
                    },
                }
            }
        },
        // 人口统计数据
        async getStatisticalCount(apiName) {
            try {
                let param = {spaceCode: this.villageCode}
                let result = await this.$apis.ODC(apiName, param);
                if (this.$apis.isOk(result)) {
                    return result
                } else {
                    this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
                }
            } catch (err) {
                this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
            }
        }
    },
    async created() {
        // No Code To Return
        this.villageCode = this.$store.state.auth.space.space_code ? this.$store.state.auth.space.space_code : (this.$store.state.auth.spaces.length > 0 ? this.$store.state.auth.spaces.length[0].space_code : "")
        if(this.villageCode) {
            this.population = await this.getStatisticalCount("人口统计数据")
            this.collection = await this.getStatisticalCount("采集统计数据")
            this.device = await this.getStatisticalCount("设备统计数据")
            this.house = await this.getStatisticalCount("房屋统计数据")
        } else {
            this.population = {
                localCount: 0,
                outCount: 0
            }
            this.collection  = {
                allCollectionCount: 0,
                cardCount: 0,
                faceCount: 0
            }
            this.device = {
                accessCount: 0,
                cameraCount: 0,
                nbCount: 0,
                otherCount: 0
            }
            this.house = {
                noPeopleHouseCount: 0,
                ownerHouseCount: 0,
                rentHouseCount: 0
            }
        }
    },
}
</script>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #efefef;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>